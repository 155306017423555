import React from 'react';
import styled from 'styled-components';
import kazaplaLogo from '../assets/KazaplanLogo.svg';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  background-color: #f5f5f5;
  text-align: center;
`;

const Icon = styled.img`
  width: 100px; 
  height: 100px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 2em;
  color: #333;
  margin-bottom: 10px;
`;

const Message = styled.p`
  font-size: 1.2em;
  color: #666;
`;

// Composant générique
const GenericErrorPage = ({ titleKey, messageKey }) => {

const { t } = useTranslation(); // Utilisation de i18next pour les traductions
    return (<Container>
            <Icon src={kazaplaLogo} alt="Generic icon" />
            <Title>{t(titleKey)}</Title>
            <Message>{t(messageKey)}</Message>
        </Container>
    );
}


GenericErrorPage.propTypes = {
    titleKey: PropTypes.string.isRequired,
    messageKey: PropTypes.string.isRequired,
};

export default GenericErrorPage;
