// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(Backend) // Charge les traductions depuis le dossier public/locales
    .use(LanguageDetector) // Détecte la langue du navigateur
    .use(initReactI18next) // Intègre i18next avec React
    .init({
        fallbackLng: 'en', // Langue par défaut si la détection échoue
        debug: false, // Désactiver les messages de débogage en production

        // Options du backend
        backend: {
            loadPath: '/locales/{{lng}}/translation.json', // Chemin vers les fichiers de traduction
        },

        // Options d'interpolation
        interpolation: {
            escapeValue: false, // React échappe automatiquement les valeurs
        },

        // Configurations supplémentaires
        react: {
            useSuspense: false, // Désactiver Suspense pour les traductions
        },
    });

export default i18n;
