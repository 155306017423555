import React from 'react';
import { NotFound, Unauthorized, Forbidden, ServerError, BadRequest } from './ErrorsComponent';

// Mapping des statuts HTTP aux composants d'erreur
const errorComponents = {
    400: BadRequest,
    401: Unauthorized,
    403: Forbidden,
    404: NotFound,
    500: ServerError,
};

// Composant principal qui retourne le bon composant d'erreur selon le statut HTTP
const ErrorHandler = ({ status }) => {

    const ErrorComponent = errorComponents[status] || NotFound; // Si le statut n'est pas trouvé, retourne l'erreur 404 par défaut
    return <ErrorComponent />;
};

export default ErrorHandler;
