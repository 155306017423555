import React, {useEffect} from 'react';
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import axios from 'axios';
import PropTypes from "prop-types";
import useSWR from "swr";
import KazaplanIcon from '../assets/KazaplanLogo.svg';
import ErrorHandler from "./ErrorHandler";


const api_host = process.env.REACT_APP_HOST;
const api_key = process.env.REACT_APP_VIEWER_360_API_KEY;

// Utilisez cache pour encapsuler l'appel API
const fetchImage = async  (url) => {
    const response = await axios.get(url, {
        responseType: 'blob',
        headers:{
            'x-viewer360-api-key': api_key,
        }
    });
    return URL.createObjectURL(response.data);
};


const PhotoSphereViewer = ({ uuid }) => {



    const { data: imageUrl, error } = useSWR( `${api_host}/renderers/${uuid}/buffer`, fetchImage, {revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false})


    useEffect(() => {
        return () => {
            if (imageUrl) {
                URL.revokeObjectURL(imageUrl);
            }
        };
    }, [imageUrl]);


    if(error){
        return (<ErrorHandler status={error.status}  />)
    }

    return (
        <div style={{width: '100vw', height: '100vh'}}>
            <ReactPhotoSphereViewer
                src={imageUrl}
                height={'100%'}
                width={'100%'}
                defaultZoomLvl={0}
                navbar={['zoom', 'caption', 'fullscreen', ]}
                loadingImg={KazaplanIcon}
            />
        </div>
    );
};

PhotoSphereViewer.propTypes = {
    uuid: PropTypes.string.isRequired, // uuid est une chaîne de caractères requise
};
export default PhotoSphereViewer;
