import { useParams } from "react-router-dom";
import PhotoSphereViewer from "./components/PhotoSphereViewer";
import { InvalidUUID } from "./components/ErrorsComponent";
import React from "react";

const isValidUUID = (uuid) => {
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};

function App() {
  const { uuid } = useParams();

  if (isValidUUID(uuid)) {
    return <PhotoSphereViewer uuid={uuid} />;
  } else {
    return <InvalidUUID />;
  }
}

export default App;
