// ErrorsComponent.js
import React from 'react';
import GenericErrorPage from './GenericErrorPage';

// Composants spécifiques aux erreurs
const NotFound = () => (
    <GenericErrorPage titleKey="error.404.title" messageKey="error.404.message" />
);

const Unauthorized = () => (
    <GenericErrorPage titleKey="error.401.title" messageKey="error.401.message" />
);

const Forbidden = () => (
    <GenericErrorPage titleKey="error.403.title" messageKey="error.403.message" />
);

const ServerError = () => (
    <GenericErrorPage titleKey="error.500.title" messageKey="error.500.message" />
);

const BadRequest = () => (
    <GenericErrorPage titleKey="error.400.title" messageKey="error.400.message" />
);

const InvalidUUID = () => (
    <GenericErrorPage titleKey="error.invalidUUID.title" messageKey="error.invalidUUID.message" />
);

export { NotFound, Unauthorized, Forbidden, ServerError, BadRequest, InvalidUUID };
